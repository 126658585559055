<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">
        <v-icon class="white--text" left light>{{ icons.mdiAccountCircle }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        Personal Info
      </v-tab>
      <v-tab>
        <v-icon left>{{ icons.mdiAccountKey }}</v-icon>
        Password
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="user.first_name"
                    color="secondary"
                    label="First Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="user.last_name"
                    color="secondary"
                    label="Last Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.email"
                    dense
                    label="Email (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.phone"
                    dense
                    label="Phone (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-layout>
                    <v-autocomplete
                      v-model="user.role_id"
                      :items="roles"
                      item-value="role_id"
                      item-text="user_type"
                      outlined
                      color="secondary"
                      dense
                      chips
                      deletable-chips
                      small-chips
                      label="Roles/User Type"
                      :prepend-inner-icon="icons.mdiAccountEdit"
                      single-line
                    ></v-autocomplete>
                    <v-btn class="mx-2" @click.prevent="addnewRole" rounded color="primary" dark> Add Role </v-btn>
                  </v-layout>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    class="mt-1"
                    v-model="user.status"
                    :label="user.status == 1 ? 'Active' : 'Inactive'"
                    color="primary"
                    :value="user.status"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    type="password"
                    v-model="user.password"
                    color="secondary"
                    label="Password"
                    :rules="pwdRules"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    type="password"
                    v-model="user.password_confirmation"
                    color="secondary"
                    label="Retype password"
                    :rules="pwdConfirm"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="addNewUser">
              <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'user' })">
              <v-icon right dark>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <add-new-role-dialog
      :dialog.sync="showNewRoleDialog"
      @open-dialog="showNewRoleDialog = true"
      @close-dialog="showNewRoleDialog = false"
    />
    <v-card elevation="1" class="mt-5">
      <v-toolbar height="45" flat light>
        <v-toolbar-title class="secondary--text page-title">
          <v-icon class="secondary--text" left light>{{ icons.mdiAccountKeyOutline }}</v-icon> Roles and Permissions
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click.stop="" @click.prevent="addnewMenu" v-bind="attrs" v-on="on" fab dark x-small color="primary">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Menu</span>
        </v-tooltip>
      </v-toolbar>
      <v-expansion-panels inset>
        <v-expansion-panel v-for="(userMenu, index1) in user.usermenus" :key="index1">
          <v-expansion-panel-header @keyup.space.prevent>
            <template v-slot:actions>
              <v-icon color="primary" class="icon mt-n4">{{ icons.mdiMenuDown }}</v-icon>
            </template>
            <v-layout class="header" row>
              <v-col class="mt-3" cols="12" md="4" sm="4">
                <input type="hidden" v-model="userMenu.user_menu_id" />
                <input type="hidden" v-model="userMenu.submenu" />
                <input type="hidden" v-model="userMenu.permission_menuid" />
                <v-autocomplete
                  v-model="userMenu.menu_id"
                  @click.native.stop=""
                  @input="getSubmenus(index1)"
                  @focus="getSubmenus(index1)"
                  :items="menus"
                  item-value="menu_id"
                  item-text="name"
                  outlined
                  color="secondary"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  label="Menu"
                  single-line
                ></v-autocomplete>
              </v-col>
              <div class="row mt-1">
                <v-col cols="12" md="3" sm="3">
                  <v-switch
                    label="Create"
                    v-model="userMenu.create"
                    @click.stop
                    @click.prevent=""
                    color="primary"
                    :value="1"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <v-switch
                    label="Read"
                    v-model="userMenu.read"
                    @click.stop
                    @click.prevent=""
                    color="primary"
                    :value="1"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <v-switch
                    label="Update"
                    v-model="userMenu.update"
                    @click.stop
                    @click.prevent=""
                    color="primary"
                    :value="1"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <v-switch
                    label="Delete"
                    v-model="userMenu.delete"
                    @click.stop
                    @click.prevent=""
                    color="primary"
                    :value="1"
                    hide-details
                  ></v-switch>
                </v-col>
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.stop
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent="removeMenu(index1)"
                    class="mt-8 mr-2"
                    fab
                    dark
                    x-small
                    color="error"
                  >
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Delete Menu</span>
              </v-tooltip>
            </v-layout>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="userMenu.user_submenus"
              :hide-default-footer="true"
              sort-by="created_by"
              class="elevation-2"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(submenu, index2) in items" :key="index2">
                    <td width="25%">
                      <v-col cols="12" sm="12" md="12" class="ml-n5">
                        <input type="hidden" v-model="submenu.per_menu_id" />
                        <v-autocomplete
                          v-model="submenu.submenu_id"
                          :items="submenus"
                          item-value="submenu_id"
                          item-text="name"
                          outlined
                          color="secondary"
                          dense
                          chips
                          deletable-chips
                          small-chips
                          label="Submenu"
                          single-line
                        ></v-autocomplete>
                      </v-col>
                    </td>
                    <td width="10%">
                      <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                        <v-switch
                          v-model="submenu.create"
                          @change="showSubmenu(index1)"
                          label="Create"
                          color="primary"
                          :value="1"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </td>
                    <td width="10%">
                      <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                        <v-switch
                          v-model="submenu.read"
                          @change="showSubmenu(index1)"
                          label="Read"
                          color="primary"
                          :value="1"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </td>
                    <td width="10%">
                      <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                        <v-switch
                          v-model="submenu.update"
                          @change="showSubmenu(index1)"
                          label="Update"
                          color="primary"
                          :value="1"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </td>
                    <td width="10%">
                      <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                        <v-switch
                          v-model="submenu.delete"
                          @change="showSubmenu(index1)"
                          label="Delete"
                          color="primary"
                          :value="1"
                          hide-details
                        ></v-switch>
                      </v-col>
                    </td>
                    <td>
                      <v-btn
                        class="mt-n6"
                        @click.prevent="removeSubMenu(index1, index2)"
                        color="error"
                        fab
                        x-small
                        dark
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <v-btn @click.prevent="addnewSubMenu(index1)" plain color="primary">
                  <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                  Add Item
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn color="accent" @click="getPermissions"> Reset </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-card>
</template>

<script>
import AddNewRoleDialog from '@/views/settings/role/AddNewRoleModal.vue'
import { mapGetters } from 'vuex'
import {
  mdiAccountTie,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiAccountCircle,
  mdiAccountKey,
  mdiAccountEdit,
  mdiAccountKeyOutline,
  mdiMenuDown,
} from '@mdi/js'
export default {
  components: {
    AddNewRoleDialog,
  },

  created: function () {
    this.getUsers()
    this.getRoles()
    this.getMenus()
    this.getPermissions()
    window.mitt.on('loadRole', () => {
      this.getUsers()
      this.getRoles()
      this.getMenus()
      this.getPermissions()
    })
  },
  data() {
    return {
      expanded: [],
      roles: [],
      menus: [],
      submenus: [],
      search: '',
      user: {
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        email: '',
        phone: '',
        status: '',
        role_id: '',
        usermenus: [
          {
            user_menu_id: '',
            permission_id: '',
            menu_id: '',
            create: '',
            read: '',
            update: '',
            delete: '',
            submenu: '',
            user_submenus: [
              {
                user_submenu_id: '',
                user_menu_id: '',
                submenu_id: '',
                create: '',
                read: '',
                update: '',
                delete: '',
              },
            ],
          },
        ],
      },
      headers: [
        {
          text: 'Name',
          value: 'submenu_id',
        },

        {
          text: 'Create',
          value: 'create',
        },
        {
          text: 'Read',
          value: 'read',
        },
        {
          text: 'Update',
          value: 'update',
        },
        {
          text: 'Delete',
          value: 'delete',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      showNewRoleDialog: false,
      pwdRules: [v => !!v || 'Password required'],
      pwdConfirm: [v => !!v || 'Confirm password', v => v === this.user.password || 'Passwords do not match'],

      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiAccountCircle,
        mdiAccountKey,
        mdiAccountEdit,
        mdiAccountKeyOutline,
        mdiMenuDown,
      },
    }
  },
  methods: {
    getUsers: function () {
      this.$store
        .dispatch('user/fetchUsers')
        .then(response => {
          this.users = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },
    getRoles: function () {
      this.$store
        .dispatch('role/fetchRoles')
        .then(response => {
          this.roles = this.fetchRoles
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPermissions: function () {
      this.$store
        .dispatch('permission/fetchPermissions')
        .then(response => {
          this.permissions = this.fetchPermissions
        })
        .catch(err => {
          console.log(err)
        })
    },

    addNewUser: function () {
      this.$store
        .dispatch(`user/storeNewUser`, this.user)
        .then(response => {
          this.$router.push({ name: 'user' })
        })
        .catch(error => {
          console.log(error)
        })
    },

    addNewTimesheet: function () {
      this.$store
        .dispatch(`clock/storeNewTimesheet`, this.user)
        .then(response => {})
        .catch(error => {
          console.log(error)
        })
    },
    addnewRole: function () {
      this.showNewRoleDialog = true
    },
    getMenus: function () {
      this.$store
        .dispatch('menu/fetchMenus')
        .then(response => {
          this.menus = this.fetchMenus
        })
        .catch(err => {
          console.log(err)
        })
    },

    getSubmenus: function (index1) {
      this.$store
        .dispatch('menu/fetchSubMenus', {
          menu_id: this.user.usermenus[index1].menu_id,
        })
        .then(response => {
          this.submenus = this.fetchSubMenus
        })
        .catch(err => {
          console.log(err)
        })
    },
    addnewMenu: function () {
      this.user.usermenus.push({
        user_menu_id: '',
        permission_id: '',
        menu_id: '',
        create: '',
        read: '',
        update: '',
        delete: '',
        submenu: '',
        user_submenus: [],
      })
    },

    removeMenu: function (index1) {
      this.user.usermenus.splice(index1, 1)
    },

    showSubmenu: function (index1) {
      this.user.usermenus[index1].submenu = 1
    },
    removeMenu: function (index1) {
      this.user.usermenus.splice(index1, 1)
    },

    addnewSubMenu: function (index1) {
      this.user.usermenus[index1].user_submenus.push({
        user_submenu_id: '',
        user_menu_id: '',
        submenu_id: '',
        create: '',
        read: '',
        update: '',
        delete: '',
      })
    },

    removeSubMenu: function (index1, index2) {
      this.user.usermenus[index1].user_submenus.splice(index2, 1)
    },
  },
  computed: {
    ...mapGetters({
      fetchPermissions: 'permission/fetchPermissions',
      fetchRoles: 'role/fetchRoles',
      fetchUsers: 'user/fetchUsers',
      fetchMenus: 'menu/fetchMenus',
      fetchSubMenus: 'menu/fetchSubMenus',
    }),
  },
}
</script>

<style lang="scss" scoped>
.icon {
  order: 0;
}
.header {
  order: 1;
}
</style>